import { USStateAndTerritoriesType, USStatesAndTerritories } from '@rategravity/core-lib/enums';
import * as t from 'type-shift';

export interface Constraints {
  state: USStateAndTerritoriesType;
  scenario: 'purchase' | 'refinance';
  occupancy: 'PrimaryResidence' | 'SecondHome' | 'InvestmentProperty';
}

export const constraintsConverter = t.strict({
  state: t.oneOf(USStatesAndTerritories),
  scenario: t.oneOf(['purchase', 'refinance']),
  occupancy: t.oneOf(['PrimaryResidence', 'SecondHome', 'InvestmentProperty'])
});

export type AutoQuoteConstraints = [Partial<Constraints>, boolean];

export const autoQuoteConstraintConverter: t.Converter<AutoQuoteConstraints> = t
  .strict<AutoQuoteConstraints>([t.partial(constraintsConverter), t.boolean])
  .pipe((c) => [c[0], c[1]]);

/**
 * A lender has an id, name, optional array enabledStates
 * that holds the states the lender is active in, and an
 * optional autoQuoteConstraints array.
 */
export interface Lender {
  id: string;
  name: string;
  states: {
    autoQuoteConstraints?: AutoQuoteConstraints[];
    enabledStates?: USStateAndTerritoriesType[];
    licensedStates: USStateAndTerritoriesType[];
  };
  autoQuotable: boolean;
  fieldStatus: {
    states: 'dirty' | 'saved' | 'saving';
  };
}

/**
 * Each lender is indexed by its id under the byId object.
 * The allIds array holds an array of all the lender ids.
 */
export interface LenderState {
  byId: Record<string, Lender>;
  allIds: string[];
}
