import { OwnUpThemeWrapper } from '@rategravity/own-up-component-library';
import React from 'react';
import { render } from 'react-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { App } from './components/app';
import { store } from './redux/store';

render(
  <Provider store={store}>
    <Helmet>
      <title>Lender Configuration</title>
    </Helmet>
    <OwnUpThemeWrapper>
      <Router>
        <Switch>
          <Route path="/">
            <App />
          </Route>
        </Switch>
      </Router>
    </OwnUpThemeWrapper>
  </Provider>,
  document.getElementById('root')
);
