import { USStateAndTerritoriesType } from '@rategravity/core-lib/enums';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from '../reducers';
import { rootSaga } from '../sagas';
import { LenderState } from './lender/types';

export interface AppState {
  activeConfig: string;
  activeStates: USStateAndTerritoriesType[];
  editable: boolean;
  error: boolean;
  lenders: LenderState;
  loading: { lenders: boolean; activeStates: boolean };
  saving: boolean;
  lastModifiedTime: number | null;
  showUnlicensed: boolean;
}

const sagaMiddleware = createSagaMiddleware();

// Attempt to hook up REDUX DevTools if they exist...
const composeEnhancers = (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);
