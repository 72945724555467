import React, { useEffect } from 'react';
import { AuthHeader } from '../../components/auth-header';
import { UnauthHeader } from '../../components/unauth-header';
import { handleLoginCallback } from '../../modules/authentication';

export const Auth = () => {
  useEffect(() => {
    try {
      handleLoginCallback();
    } catch {
      window.location.href = window.location.origin + '/unauthorized';
    }
  }, []);
  return <AuthHeader />;
};

export const Unauthorized = () => {
  return <UnauthHeader />;
};
