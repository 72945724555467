import { OwnUpBox, OwnUpDropDown, OwnUpIcon } from '@rategravity/own-up-component-library';
import * as colors from '@rategravity/own-up-component-library/theme/colors';
import React from 'react';
import { LenderColumnState } from './properties';

const buildDisplay = (label: string, color: string) => (
  <OwnUpBox>
    <OwnUpIcon
      icon="Circle"
      width="8px"
      style={{ paddingRight: '4px' }}
      color={color}
      variant="noTop"
      fill
    />
    {label}
  </OwnUpBox>
);

const optionAuto = {
  display: buildDisplay('Auto (All)', colors.SUCCESS),
  variant: 'success' as const,
  value: LenderColumnState.AUTOQUOTABLE
};

const optionManual = {
  display: buildDisplay('Manual', colors.WARN),
  variant: 'warn' as const,
  value: LenderColumnState.MANUAL
};

const optionDisabled = {
  display: buildDisplay('Disabled', colors.DANGER),
  variant: 'danger' as const,
  value: LenderColumnState.DISABLED
};

const optionAutoPurchase = {
  display: buildDisplay('Purchase', colors.SUCCESS),
  variant: 'success' as const,
  value: LenderColumnState.AQ_PURCHASE
};

const optionAutoNonInvestment = {
  display: buildDisplay('Non-Invest', colors.SUCCESS),
  variant: 'success' as const,
  value: LenderColumnState.AQ_NON_INVESTMENT
};

const optionAutoRefinance = {
  display: buildDisplay('Refinance', colors.SUCCESS),
  variant: 'success' as const,
  value: LenderColumnState.AQ_REFINANCE
};

type BoundUpdateLender = (newSetting: LenderColumnState) => void;

export const renderEditableCell = (
  stateSetting: LenderColumnState,
  isAutoQuotable: boolean,
  updateLender: BoundUpdateLender,
  editable: boolean,
  key: string
) => {
  const onChange = (newStateSetting: LenderColumnState) => {
    updateLender(newStateSetting);
  };

  const options = [
    ...(isAutoQuotable
      ? [optionAuto, optionAutoPurchase, optionAutoNonInvestment, optionAutoRefinance]
      : []),
    optionManual,
    optionDisabled
  ];

  return (
    <OwnUpDropDown
      key={key}
      value={stateSetting}
      variant="advisorSide"
      options={options}
      onChange={onChange}
      disabled={!editable}
    />
  );
};
