import { OwnUpBox, OwnUpButton, OwnUpIcon, OwnUpText } from '@rategravity/own-up-component-library';
import React, { useCallback } from 'react';
import { getTimeSince } from '../../modules/format-time-since';
import { PublishButtonProps } from './properties';

const publishText = ({
  saving,
  dirty,
  lastModifiedTime
}: {
  saving: boolean;
  dirty: boolean;
  lastModifiedTime: number | null;
}) => {
  const TimeElement = useCallback(() => getTimeSince(lastModifiedTime), [lastModifiedTime]);
  const Element = useCallback(() => {
    if (saving) {
      return <React.Fragment>Saving...</React.Fragment>;
    } else if (dirty) {
      return (
        <React.Fragment>
          Last change made <TimeElement />
        </React.Fragment>
      );
    }
    return <React.Fragment>You haven&apos;t made any changes.</React.Fragment>;
  }, [dirty, saving, TimeElement]);
  return <Element />;
};

export const PublishButton = ({
  saving,
  dirty,
  lastModifiedTime,
  modalController
}: PublishButtonProps) => {
  return (
    <OwnUpBox variant="end">
      <OwnUpText variant={['alt', 'end']}>
        {publishText({
          saving,
          dirty,
          lastModifiedTime
        })}
      </OwnUpText>
      <OwnUpButton
        variant="square"
        disabled={!dirty || saving}
        onClick={() => modalController.show()}
        style={{ margin: '0 22px' }}
      >
        <OwnUpIcon icon="ArrowUpRight" />
        Publish changes
      </OwnUpButton>
    </OwnUpBox>
  );
};
