import { USStateAndTerritoriesType } from '@rategravity/core-lib/enums';
import {
  createOwnUpComponent,
  OwnUpBox,
  OwnUpColumns,
  OwnUpHeader,
  OwnUpTable,
  useModalController
} from '@rategravity/own-up-component-library';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveLendersAction,
  setLastModifiedTimeAction,
  updateLenderAction
} from '../../redux/actions';
import {
  anyDirtyLendersSelector,
  isEditableSelector,
  isSavingSelector,
  lastModifiedTimeSelector
} from '../../redux/selectors';
import { lenderRowSelector, stateHeaderSelector } from '../../redux/selectors/lender-state-table';
import { renderHeaders } from './headers';
import { LenderColumnState, LenderTableProps, TableImplProps } from './properties';
import { PublishButton } from './publish-button';
import { ReasonModal } from './reason-modal';
import { renderRows } from './rows';

const StyledTable = createOwnUpComponent(OwnUpTable, {
  maxWidth: '100%',
  maxHeight: '77vh',
  '&>td:first-child,th:first-child': {
    minWidth: '330px'
  },
  margin: '0px',
  '.ou-table__td': {
    overflow: 'visible' // Allows dropdowns to expand beyond their cells
  }
});

export const LenderTableImpl = ({
  headers,
  rows,
  saving,
  title,
  dirty,
  modalController,
  publish,
  updateLender,
  lastModifiedTime,
  editable
}: TableImplProps) => (
  <React.Fragment>
    <ReasonModal modalController={modalController} publish={publish} />
    <OwnUpColumns>
      <OwnUpBox>
        <OwnUpHeader variant={['start']} style={{ fontSize: '32px' }}>
          {title}
        </OwnUpHeader>
      </OwnUpBox>

      {editable ? (
        <PublishButton
          modalController={modalController}
          saving={saving}
          dirty={dirty}
          lastModifiedTime={lastModifiedTime}
        />
      ) : null}
    </OwnUpColumns>
    <StyledTable
      variant={['striped', 'stickyHeader', 'stickyColumn']}
      headers={renderHeaders(headers)}
      data={renderRows({
        editable,
        headers,
        rows,
        updateLender
      })}
    />
  </React.Fragment>
);

export const LenderTable = ({ title }: LenderTableProps) => {
  const headers = useSelector(stateHeaderSelector);
  const dirty = useSelector(anyDirtyLendersSelector);
  const saving = useSelector(isSavingSelector);
  const rows = useSelector(lenderRowSelector);
  const lastModifiedTime = useSelector(lastModifiedTimeSelector);
  const editable = useSelector(isEditableSelector);

  // Store a reference to the saving promise resolve method, as a
  //   change in the redux state will be the thing that triggers
  //   its resolution in a useEffect block below.
  const savingResolutionRef = useRef<((result: boolean) => void) | null>(null);

  const dispatch = useDispatch();
  const publish = (reason: string) => {
    if (savingResolutionRef.current) {
      // Fail immediately if a save is already in progress, as this
      //   is a state that should be impossible to get to.
      return Promise.reject();
    }
    // Dispatch the save operation and return a Promise that will
    //   be resolved by a useEffect block that is monitoring changes
    //   in the redux state below.
    dispatch(saveLendersAction(reason));
    return new Promise<boolean>((resolve) => {
      savingResolutionRef.current = resolve;
    });
  };

  // Create an effect that will monitor the saving state of the
  //   redux store and resolve any outstanding promises for saving.
  useEffect(() => {
    if (savingResolutionRef.current && !saving) {
      savingResolutionRef.current(true);
      savingResolutionRef.current = null;
    }
  }, [savingResolutionRef, saving]);

  const updateLender = (
    id: string,
    state: USStateAndTerritoriesType,
    setting: LenderColumnState
  ) => {
    dispatch(
      updateLenderAction({
        id,
        state,
        setting
      })
    );

    dispatch(setLastModifiedTimeAction(Date.now()));
  };

  const modalController = useModalController();

  const implProps: TableImplProps = {
    title,
    rows,
    saving,
    updateLender,
    publish,
    modalController,
    dirty,
    headers,
    lastModifiedTime,
    editable
  };
  return <LenderTableImpl {...implProps} />;
};
