import { USStateAndTerritoriesType } from '@rategravity/core-lib/enums';
import { LenderColumnState } from '../components/lender-table/properties';
import { AutoQuoteConstraints } from '../redux/store/lender/types';
/**
 * Takes constraints from the backend and interprets which LenderColumnState should
 * be assigned to a given state
 *
 * @param constraintsArr constraints table
 * @param state the state to assign a LenderColumnState enum value to
 * @param autoQuotable boolean indicating we have an OB profile for the lender
 */

export const parseConstraints = (
  constraintsArr: AutoQuoteConstraints[] = [],
  state: USStateAndTerritoriesType,
  autoQuotable: boolean
) => {
  const constrainedByState = constraintsArr.filter(
    ([constraints, bool]) =>
      !bool && constraints.state && constraints.state.includes(state as USStateAndTerritoriesType)
  );
  if (constrainedByState.length > 0) {
    const constrainedByRefinanceScenario = constrainedByState.filter(([constraints]) =>
      Object.entries(constraints).some(
        ([key, value]) => key === 'scenario' && value === 'refinance'
      )
    );

    const constrainedByPurchaseScenario = constrainedByState.filter(([constraints]) =>
      Object.entries(constraints).some(([key, value]) => key === 'scenario' && value === 'purchase')
    );

    const constrainedByOccupancy = constrainedByState.filter(([constraints]) =>
      Object.entries(constraints).some(
        ([key, value]) => key === 'occupancy' && value === 'InvestmentProperty'
      )
    );
    return constrainedByRefinanceScenario.length > 0 && autoQuotable
      ? LenderColumnState.AQ_PURCHASE
      : constrainedByPurchaseScenario.length > 0 && autoQuotable
      ? LenderColumnState.AQ_REFINANCE
      : constrainedByOccupancy.length > 0 && autoQuotable
      ? LenderColumnState.AQ_NON_INVESTMENT
      : LenderColumnState.MANUAL;
  }
  return autoQuotable ? LenderColumnState.AUTOQUOTABLE : LenderColumnState.MANUAL;
};
