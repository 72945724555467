import { USStateAndTerritoriesType, USStatesAndTerritories } from '@rategravity/core-lib/enums';
import * as t from 'type-shift';
import { autoQuoteConstraintConverter, AutoQuoteConstraints, Lender } from '../store/lender/types';
interface LenderData {
  name: string;
  states: {
    enabledStates?: USStateAndTerritoriesType[];
    licensedStates: USStateAndTerritoriesType[];
    autoQuoteConstraints?: AutoQuoteConstraints[];
  };
  autoQuotable: boolean;
}

const lenderDataConverter = t.shape<LenderData>({
  name: t.string,
  states: t.shape({
    enabledStates: t.optional(t.array(t.oneOf(USStatesAndTerritories))),
    licensedStates: t.array(t.oneOf(USStatesAndTerritories)),
    autoQuoteConstraints: t.optional(t.array(autoQuoteConstraintConverter))
  }),
  autoQuotable: t.boolean
});

/**
 * This function flattens out the lender data into an array
 * of lender shaped objects, that is then fed to the reducer.
 * @param resData The server response from hitting /lender
 */
export const parseLenders = (data: any): Lender[] => {
  const lenderData = t.record(lenderDataConverter)(data);
  const lenderIds = Object.keys(lenderData);

  return lenderIds.map((id: string) => ({
    id,
    ...lenderData[id],
    fieldStatus: {
      states: 'saved'
    }
  }));
};
