import { combineReducers } from 'redux';
import { getIdToken } from '../../modules/authentication';
import {
  BEGIN_LOADING,
  BEGIN_SAVING,
  BeginLoadingAction,
  BeginSavingAction,
  END_LOADING,
  END_SAVING,
  EndLoadingAction,
  EndSavingAction,
  SELECT_ACTIVE_CONFIG,
  SelectActiveConfigAction,
  SET_ERROR,
  SET_LAST_MODIFIED_TIME,
  SetErrorAction,
  SetLastModifiedTimeAction
} from '../actions/action-types';
import { activeStatesReducer } from './active-states';
import { lenderReducer } from './lenders';

const initialActiveConfig = 'states';

const selectConfigReducer = (
  state: string = initialActiveConfig,
  action: SelectActiveConfigAction
): string => {
  const { type, payload } = action;
  switch (type) {
    case SELECT_ACTIVE_CONFIG:
      return payload;
    default:
      return state;
  }
};

const loadingReducer = (
  state: { lenders: boolean; activeStates: boolean } = { lenders: false, activeStates: false },
  action: BeginLoadingAction | EndLoadingAction
): { lenders: boolean; activeStates: boolean } => {
  const { type, payload } = action;
  switch (type) {
    case BEGIN_LOADING:
      return { ...state, [payload.slice]: true };
    case END_LOADING:
      return { ...state, [payload.slice]: false };
    default:
      return state;
  }
};

const savingReducer = (state: boolean = false, action: BeginSavingAction | EndSavingAction) => {
  const { type } = action;
  switch (type) {
    case BEGIN_SAVING:
      return true;
    case END_SAVING:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state: boolean = false, action: SetErrorAction) => {
  const { type } = action;
  switch (type) {
    case SET_ERROR:
      return true;
    default:
      return state;
  }
};

const lastModifiedTimeReducer = (
  state: number | null = null,
  action: SetLastModifiedTimeAction
) => {
  switch (action.type) {
    case SET_LAST_MODIFIED_TIME:
      return action.payload.lastModifiedTime;
    default:
      return state;
  }
};

const decodeToken = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
};

/**
 * Groups who can edit the lenders.
 */
const editorGroups = ['dev', 'lender-management'];

export const getEditableState = () => {
  const token = getIdToken();
  const decode = token ? decodeToken(token) : {};
  const groups: string[] = decode['https://auth.ownup.com/groups'];
  return !!groups?.some((group) => editorGroups.includes(group));
};

export const rootReducer = combineReducers({
  activeConfig: selectConfigReducer,
  activeStates: activeStatesReducer,
  editable: getEditableState,
  error: errorReducer,
  lastModifiedTime: lastModifiedTimeReducer,
  lenders: lenderReducer,
  loading: loadingReducer,
  saving: savingReducer,
  showUnlicensed: () => window.location.search.includes('unlicensed')
});
