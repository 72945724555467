import { USStateAndTerritoriesType } from '@rategravity/core-lib/enums';
import { OwnUpModalController } from '@rategravity/own-up-component-library';

type PublishFunction = (reason: string) => Promise<boolean>;

export enum LenderColumnState {
  DIVIDER, // Visual-only demarcation.
  UNLICENSED, // Not eligible for quoting.
  DISABLED, // Eligible for quoting, but not available.
  MANUAL, // Eligible for quoting and available, but not automatically. Also 'Enabled'.
  AUTOQUOTABLE, // Eligible for quoting and available automatically. Also 'Auto'.
  AQ_PURCHASE, // Eligible for quoting and available automatically for purchases.
  AQ_NON_INVESTMENT, // Eligible for quoting and available automatically for non-investment properties.
  AQ_REFINANCE // Eligible for quoting and available automatically for refinances.
}

export interface LenderRowInfo {
  name: string;
  autoQuotable: boolean;
  stateSettings: LenderColumnState[];
}

export interface TableImplProps {
  headers: string[];
  rows: Record<string, LenderRowInfo>;
  title: string;
  updateLender: (
    id: string,
    state: USStateAndTerritoriesType,
    newSetting: LenderColumnState
  ) => void;
  publish: PublishFunction;
  saving: boolean;
  dirty: boolean;
  lastModifiedTime: number | null;
  editable: boolean;
  modalController: OwnUpModalController;
}

export interface LenderTableProps {
  title: string;
}

export interface PublishButtonProps {
  saving: boolean;
  dirty: boolean;
  lastModifiedTime: number | null;
  modalController: OwnUpModalController;
}

export interface ReasonModalProps {
  modalController: OwnUpModalController;
  publish: PublishFunction;
}
