import { createSelector } from 'reselect';
import { AppState } from '../store';
import { Lender, LenderState } from '../store/lender/types';

export const lenderStateSelector = (state: AppState) => state.lenders;

/**
 * Return the mapping of lender ids to lenders
 */
export const lendersSelector = createSelector(
  lenderStateSelector,
  (lenderState: LenderState) => lenderState.byId
);

/**
 * Return lenders that have been modified and not saved
 */
export const dirtyLendersSelector = createSelector(
  lenderStateSelector,
  (lenderState: LenderState) =>
    lenderState.allIds.reduce((acc, lenderId) => {
      const lender = lenderState.byId[lenderId];
      if (lender && lender.fieldStatus?.states !== 'saved') {
        acc[lenderId] = lender;
      }
      return acc;
    }, {} as Record<string, Lender>)
);

/**
 * Returns true if any lenders have state configurations that are dirty.
 */
export const anyDirtyLendersSelector = createSelector(
  dirtyLendersSelector,
  (lenders) => Object.keys(lenders).length > 0
);
